import { Metrics as OriginalMetrics } from "../../../../../js/components/Metrics/Metrics"
import omit from "lodash/omit"

export const Metrics = (props) => {
  return (
    <OriginalMetrics
      title={props.title}
      color="primary"
      excerpt={props.excerpt}
      metrics={omit(props.metrics, ["nbConnectedCrew", "nbConnectedOwner"])}
    />
  )
}
